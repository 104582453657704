<template>
  <!-- 角色菜单权限 -->
  <div class="userRolePage">
    <div class="topDiv">
      <div class="topDivfl flDiv pagePadding">
        <div class="pageTitle">角色列表</div>
        <Table ref="table1" :columns="listColumns" :data="listData" stripe border :loading="isLoad"></Table>
        <div v-if="listData.length == 0 && !isLoad" class="noData">
          <img src="../../assets/images/tableNoData.png" />
        </div>
      </div>
      <div class="topDivfl pagePadding">
        <div class="pageTitle">菜单列表</div>
        <Tree :data="baseData" show-checkbox expand-node ref="tree" @on-check-change="checkChange" :render="renderContent"></Tree>
      </div>
    </div>
    <div class="btmDiv">
      <span class="pageBtn finger btnSure" @click="updateRight" v-if="modifyAble">保存</span>
      <span class="pageBtn finger btnReset marginLeft20" @click="clearData">取消</span>
    </div>
  </div>
</template>

<script>
import clientManageFrameVue from '../msgManage/clientManageChild/clientManageFrame.vue'
export default {
  name: 'userRole',
  data() {
    return {
      isLoad: true,
      listData: [],
      listColumns: [
        {
          title: ' ',
          align: 'center',
          key: 'checkBox',
          width: 68,
          render: (h, params) => {
            return h('div', [
              h('Checkbox', {
                props: {
                  value: params.row.checkBox,
                },
                on: {
                  'on-change': e => {
                    this.listData.forEach(items => {
                      // 先取消所有对象的勾选，checkBox设置为false
                      this.$set(items, 'checkBox', false)
                    })
                    if (e) {
                      this.listData[params.index].checkBox = e // 再将勾选的对象的checkBox设置为true
                      this.role_id = params.row.id
                      if (this.role_id == 9 || this.role_id == 12) {
                        this.baseData = this.supplierData
                      } else {
                        this.baseData = this.manageData
                      }
                      this.cancleSelection(this.role_id)
                    } else {
                      this.role_id = null
                      let nodes = this.$refs.tree.getCheckedAndIndeterminateNodes() // 获取树的选中及半选中的节点
                      for (const item of nodes) {
                        // 遍历节点，然后将其关闭
                        this.$set(item, 'checked', false)
                        this.$set(item, 'indeterminate', false)
                        this.$set(item, 'expand', false)
                      }
                    }
                  },
                },
              }),
            ])
          },
        },
        {
          title: '序号',
          type: 'index',
          width: 68,
          align: 'center',
        },
        {
          title: '角色名称',
          key: 'role_name',
          align: 'center',
        },
      ],
      baseData: [], // 显示菜单列表数据
      manageData: [], // 经营端菜单权限
      supplierData: [], // 供应商端菜单权限
      role_id: '',
      role_row: [],
      pages: {
        rows: 20,
        page: 1,
      },
      // 以下是权限按钮显示
      deleteAble: false, // 删除权限
      insertAble: false, // 插入权限
      modifyAble: false, // 修改权限
      searchAble: false, // 查找权限
    }
  },
  methods: {
    // 根据id显示对应角色的权限
    cancleSelection(role_id) {
      if (role_id == 9 || role_id == 12) {
        let nodes = this.$refs.tree.getCheckedAndIndeterminateNodes() // 获取树的选中及半选中的节点
        for (const item of nodes) {
          // 遍历节点，然后将其关闭
          item.checked = false
          item.indeterminate = false
          item.expand = false
        }
        this.$http.getOne(this.$api.supplierPermissionRoleIdList, role_id).then(res => {
          if (res.status) {
            let rightList = res.data
            for (const item of this.baseData) {
              // 一级菜单循环
              if (
                rightList.find(val => {
                  // 如果选中菜单存在则返回
                  return val.func_id === item.func_id
                })
              ) {
                if (item.children.length === 0) {
                  // 如果没有子菜单，直接选中
                  this.$set(item, 'checked', true)
                } else {
                  this.$set(item, 'indeterminate', true) // 如果有子菜单，则半选
                  this.$set(item, 'expand', true)
                  for (const son of item.children) {
                    // 循环二级子菜单
                    if (
                      rightList.find(val => {
                        return val.func_id === son.func_id // 如果有被选中则同上
                      })
                    ) {
                      if (son.children.length === 0) {
                        // 如果二级没有子菜单，则直接选中
                        this.$set(son, 'checked', true)
                      } else {
                        this.$set(son, 'indeterminate', true)
                        this.$set(son, 'expand', true)
                        for (const sson of son.children) {
                          // 循环三级子菜单
                          if (
                            rightList.find(val => {
                              return val.func_id === sson.func_id
                            })
                          ) {
                            this.$set(sson, 'checked', true) // 如果被选中，则被选中
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            let afterNodes = this.$refs.tree.getCheckedAndIndeterminateNodes() // 获取对应角色id树的选中及半选中的节点
            this.role_row = afterNodes
          }
        })
      } else {
        let nodes = this.$refs.tree.getCheckedAndIndeterminateNodes() // 获取树的选中及半选中的节点
        for (const item of nodes) {
          // 遍历节点，然后将其关闭
          item.checked = false
          item.indeterminate = false
          item.expand = false
        }
        this.$http.getOne(this.$api.permissionRoleIdList, role_id).then(res => {
          if (res.status) {
            let rightList = res.data
            for (const item of this.baseData) {
              // 一级菜单循环
              if (
                rightList.find(val => {
                  // 如果选中菜单存在则返回
                  return val.func_id === item.func_id
                })
              ) {
                if (item.children.length === 0) {
                  // 如果没有子菜单，直接选中
                  this.$set(item, 'checked', true)
                } else {
                  this.$set(item, 'indeterminate', true) // 如果有子菜单，则半选
                  this.$set(item, 'expand', true)
                  for (const son of item.children) {
                    // 循环二级子菜单
                    if (
                      rightList.find(val => {
                        return val.func_id === son.func_id // 如果有被选中则同上
                      })
                    ) {
                      if (son.children.length === 0) {
                        // 如果二级没有子菜单，则直接选中
                        this.$set(son, 'checked', true)
                      } else {
                        this.$set(son, 'indeterminate', true)
                        this.$set(son, 'expand', true)
                        for (const sson of son.children) {
                          // 循环三级子菜单
                          if (
                            rightList.find(val => {
                              return val.func_id === sson.func_id
                            })
                          ) {
                            this.$set(sson, 'checked', true) // 如果被选中，则被选中
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            let afterNodes = this.$refs.tree.getCheckedAndIndeterminateNodes() // 获取对应角色id树的选中及半选中的节点
            this.role_row = afterNodes
          }
        })
      }
    },
    checkChange(selectList) {
      if (this.role_id) {
        this.role_row = selectList
        console.log(this.role_row)
      } else {
        this.$Message.error('请选择角色')
        let nodes = this.$refs.tree.getCheckedAndIndeterminateNodes() // 获取树的选中及半选中的节点
        for (const item of nodes) {
          // 遍历节点，然后将其关闭
          item.checked = false
          item.indeterminate = false
          item.expand = false
        }
      }
      // console.log(this.role_row)
    },
    /* 获取角色列表 */
    getRoleList() {
      this.isLoad = true
      this.$http.get(this.$api.permissionRoleList, this.pages).then(res => {
        this.isLoad = false
        if (res.status) {
          this.listData = res.data
        }
      })
    },
    /* 获取菜单列表 */
    async getRightList() {
      // 经营端菜单列表
      await this.$http.get(this.$api.permissionRightMenuList).then(res => {
        if (res.status) {
          let menuList = res.data
          for (const item of menuList) {
            item.title = item.func_name
            item.render = (h, { root, node, data }) => {
              return h(
                'span',
                {
                  style: {
                    display: 'inline-block',
                    width: '100%',
                  },
                },
                [
                  h('img', {
                    attrs: {
                      src: require('../../assets/images/icon_default1.png'),
                    },
                    /* style: {
                      marginRight: '30px',
                    }, */
                    class: 'marginRight25',
                  }),
                  h('span', data.title),
                ]
              )
            }
            for (const son of item.children) {
              this.$set(son, 'children', son.permission) // 三级菜单处理
              this.$set(son, 'title', son.func_name)
              for (const gson of son.children) {
                this.$set(gson, 'title', gson.func_name)
              }
            }
          }
          this.manageData = menuList
        }
      })
      // 供应商端菜单列表
      await this.$http.get(this.$api.supplierPermissionRightMenuList).then(res => {
        if (res.status) {
          let menuList = res.data
          for (const item of menuList) {
            item.title = item.func_name
            item.render = (h, { root, node, data }) => {
              return h(
                'span',
                {
                  style: {
                    display: 'inline-block',
                    width: '100%',
                  },
                },
                [
                  h('img', {
                    attrs: {
                      src: require('../../assets/images/icon_default1.png'),
                    },
                    class: 'marginRight25',
                  }),
                  h('span', data.title),
                ]
              )
            }
            for (const son of item.children) {
              this.$set(son, 'children', son.permission) // 三级菜单处理
              this.$set(son, 'title', son.func_name)
              for (const gson of son.children) {
                this.$set(gson, 'title', gson.func_name)
              }
            }
          }
          this.supplierData = menuList
        }
      })
      this.baseData = this.manageData
    },
    /* 修改角色权限 */
    updateRight() {
      let selectList = this.role_row
      let role_id = this.role_id
      if (role_id) {
        if (role_id == 9 || role_id == 12) {
          // console.log(role_id, selectList)
          let data = []
          for (let item of selectList) {
            if (item.func_id) {
              data.push(item.func_id)
            }
            // item.func_id ? data.push(item.func_id) : ''
          }
          data = JSON.stringify(data)
          let obj = { data, role_id }
          this.$http.post(this.$api.supplierPermissionRightList, obj).then(res => {
            if (res.status) {
              this.$Message.success('修改成功')
              // 修改成功后触发列表查询条件更新权限列表
              this.$root.eventHub.$emit('updateRightList')
              setTimeout(() => {
                this.getRightVisibal()
              }, 200)
            } else {
              this.$Message.error('修改失败')
            }
          })
          for (const item of this.listData) {
            item.checkBox = false
          }
          let nodes = this.$refs.tree.getCheckedAndIndeterminateNodes()
          for (const item of nodes) {
            this.$set(item, 'checked', false)
            this.$set(item, 'indeterminate', false)
            this.$set(item, 'expand', false)
          }
          this.role_id = 0
        } else {
          // console.log(role_id, selectList)
          let data = []
          for (let item of selectList) {
            if (item.func_id) {
              data.push(item.func_id)
            }
            // item.func_id ? data.push(item.func_id) : ''
          }
          data = JSON.stringify(data)
          let obj = { data, role_id }
          this.$http.post(this.$api.permissionRightList, obj).then(res => {
            if (res.status) {
              this.$Message.success('修改成功')
              // 修改成功后触发列表查询条件更新权限列表
              this.$root.eventHub.$emit('updateRightList')
              setTimeout(() => {
                this.getRightVisibal()
              }, 200)
            } else {
              this.$Message.error('修改失败')
            }
          })
          for (const item of this.listData) {
            item.checkBox = false
          }
          let nodes = this.$refs.tree.getCheckedAndIndeterminateNodes()
          for (const item of nodes) {
            this.$set(item, 'checked', false)
            this.$set(item, 'indeterminate', false)
            this.$set(item, 'expand', false)
          }
          this.role_id = 0
        }
      } else {
        this.$Message.error('请选择角色')
      }
    },
    /* 自定义样式列表 */
    renderContent(h, { root, node, data }) {
      return h(
        'span',
        {
          style: {
            display: 'inline-block',
            width: '100%',
          },
        },
        [
          h('img', {
            attrs: {
              src: require('../../assets/images/icon_default2.png'),
            },
            /* style: {
              marginRight: '25px',
            }, */
            class: 'marginRight25',
          }),
          h('span', data.title),
        ]
      )
    },
    // 获取按钮显示权限方法
    getRightVisibal() {
      let navName = this.$route.meta.navName
      let rightStr = JSON.parse(window.sessionStorage.getItem(navName))
      this.modifyAble = rightStr.modify
    },
    // 取消
    clearData() {
      this.role_id = ''
      this.listData.forEach(items => {
        // 先取消所有对象的勾选，checkBox设置为false
        this.$set(items, 'checkBox', false)
      })
      let nodes = this.$refs.tree.getCheckedAndIndeterminateNodes() // 获取树的选中及半选中的节点
      for (const item of nodes) {
        // 遍历节点，然后将其关闭
        item.checked = false
        item.indeterminate = false
        item.expand = false
      }
    },
  },
  mounted() {
    this.getRoleList()
    this.getRightList()
    this.getRightVisibal()
  },
}
</script>

<style scoped lang="less">
.userRolePage {
  display: flex;
  flex-direction: column;
  .topDiv {
    flex: 1;
    display: flex;
    .topDivfl {
      flex: 1;
      .pageTitle {
        color: pageTitle;
        height: 25px;
        line-height: 25px;
        margin-bottom: 18px;
        font-size: 18px;
        font-weight: bold;
      }
    }
    .flDiv {
      border-right: 1px solid #e8eaec;
    }
  }
  .ivu-tree {
    /deep/ .ivu-checkbox-wrapper {
      margin-right: -45px;
      margin-left: 23px;
    }
    /deep/ .ivu-icon {
      &::before {
        content: '';
        position: relative;
        display: inline-block;
        width: 11px;
        height: 11px;
        top: -3px;
        background-image: url('../../assets/images/tree_angel.png');
        background-repeat: no-repeat;
        transform: rotate(-45deg);
      }
    }
    /deep/ .ivu-tree-arrow-open {
      transform: rotate(-45deg);
    }
  }
  .btmDiv {
    padding-right: 21px;
    text-align: right;
    height: 75px;
    line-height: 75px;
    border-top: 2px solid #e8eaec;
  }
  /deep/.marginRight25 {
    margin-right: 25px;
  }
}
</style>
